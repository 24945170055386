// import C from './config';
const C = require('./config');
function appendProtocol(url) {
    return url.includes('http') ? url : `${C.projects.core.api}/${url}`;
}

const dashboardJwtSections = [
    'dashboard',
    '/statistics/v1',
    'stats',
    'signature'
];

const oAuthSections = [
    'api/',
    'oauth',
    'me'
]

function getHeaders(url, token) {
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    };
    // browser
    if (typeof window !== "undefined") {
        if (url) {
            if (dashboardJwtSections.some(e => url.includes(e))) {
                const storedUser = sessionStorage.getItem(C.sessionName);
                if (storedUser && storedUser !== 'undefined') {
                    headers.Authorization = `Bearer ${JSON.parse(storedUser).dashboard.token}`;
                }
            }
            if (oAuthSections.some(e => url.includes(e))) {
                const storedUser = sessionStorage.getItem(C.authSessionName);
                if (storedUser && storedUser !== 'undefined') {
                    headers.Authorization = `Bearer ${JSON.parse(storedUser).token}`;
                }
            }
        }
        if (token) {
            headers.Authorization = `Bearer ${token}`;
        }
    } else {
        // Backend case
        if (token) {
            headers.Authorization = token;
            headers['AddApptr-Auth-JWT'] = token;
        }
    }
    return headers;
}

const parseJson = async response => {
    if (!response.clone) return response;
    try {
        const json = await response.clone().json();
        return json;
    } catch (err) {
        const text = await response.clone().text();
        return text
    }
}

const handleError = function (err) {
    console.warn('Error on fetch', err);
    return { json: () => [] };
};

async function get(url, token, noParse) {
    const finalUrl = `${appendProtocol(url)}`;
    const response = await fetch(finalUrl, {
        method: "get",
        headers: getHeaders(url, token),
    }).catch(handleError);
    if (noParse) return response;
    const jsonResponse = await parseJson(response);
    return jsonResponse;
}

async function getForm(url, debug) {
    const finalUrl = `${appendProtocol(url)}`;
    const fetchOptions = {
        method: "get",
        headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
    };
    if (debug) console.info('fetching:', url, fetchOptions);
    const response = await fetch(finalUrl, fetchOptions).catch(handleError);
    const jsonResponse = await parseJson(response);
    return jsonResponse;
}

async function post(url, data, redirect, debug, noJson) {
    const finalUrl = `${appendProtocol(url)}`;
    const fetchOptions = {
        method: "post",
        headers: getHeaders(url),
        //make sure to serialize your JSON body
        body: JSON.stringify(data)
    };

    if(url.includes('dashboard')) fetchOptions.referer = C.projects.dashboard.prod.frontend;
    if (debug) console.info('fetching:', url);
    if (redirect) fetchOptions.redirect = 'follow';
    const response = await fetch(finalUrl, fetchOptions).catch(handleError);
    if (noJson) return response;
    const jsonResponse = await parseJson(response);
    return jsonResponse;
}

async function postForm(url, data, debug) {
    const finalUrl = `${appendProtocol(url)}`;
    var formBody = [];
    for (var property in data) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(data[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    const fetchOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
        body: formBody
    };
    if (debug) console.info('fetching:', url)
    const response = await fetch(finalUrl, fetchOptions).catch(handleError);
    const jsonResponse = await parseJson(response);
    return jsonResponse;
}

async function getDataForm(url, data, debug) {
    const finalUrl = `${appendProtocol(url)}`;
    const log = console.log;
    var formBody = [];
    for (var property in data) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(data[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    const testUrl = finalUrl + '?' + formBody;
    const fetchOptions = {
        method: 'GET',
        //mode: 'no-cors',
        redirect: 'follow',
        headers: { 'Content-Type': 'application/json' },
    };
    if (debug || true) console.info('fetching:', url);
    try {
        const response = await fetch(testUrl, fetchOptions);
        // log('response', response.body);
        return await response.json()
    } catch (error) {
        log('4', error);
        return { marco: false };
    }
}

module.exports = {
    get,
    getForm,
    post,
    postForm,
    getDataForm,
    parseJson
}