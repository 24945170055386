import React, { useState, useEffect } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { JsonEditor } from 'json-edit-react'
import C from './../config';

const api = C.projects.core.api

const AdminConf = (props) => {
    const [selected, setSelected] = useState();

    useEffect(() => {
        read()
    }, []);

    async function read() {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': props.token
            },
        };
        const uri = `${api}/admin/config`;
        const res = await fetch(uri, requestOptions)
        let resJson = await res.json();
        setSelected(resJson[0])
    }

    async function update(obj) {
        obj.newData._id = selected._id;
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': props.token
            },
            body: JSON.stringify(obj.newData)
        };
        const createRes = await fetch(`${api}/admin/config`, requestOptions);
        window.toast(createRes.status == 200 ? "Expense Report Updated" : "Unauthorized access.");
    }

    const editableData = {...selected};
    delete editableData._id;
    delete editableData.__v;

    return (<div>
        <pre>
            <JsonEditor
                data={editableData}
                onUpdate={update}
                maxWidth={'100%'}
            />
        </pre>
    </div>)
}

const Admin = () => {
    const [token, setToken] = useState(null);
    const [selected, setSelected] = useState(0);

    useEffect(() => {
        const preGoogleCookie = JSON.parse(localStorage.getItem(C.googleSessionName));
        if (preGoogleCookie) parseJwt({ credential: preGoogleCookie.token })
    }, []);

    const parseJwt = async (cred) => {
        const { credential } = cred;
        try {
            const isValid = await fetch(`${api}/expenses/validateToken`, {
                headers: {
                    'content-type': 'application/json',
                    'accept': 'application/json',
                    'Authorization': credential
                },
                method: 'GET'
            });
            const isvalidJson = await isValid.json();
            if (!isvalidJson.verified) {
                setToken(null);
            } else {
                localStorage.setItem(C.googleSessionName, JSON.stringify(isvalidJson));
                setToken(isvalidJson);
            }
        } catch (e) {
            setToken(null);
        }
    };

    return (
        <div style={{ width: '90%', padding: '2%', margin: 'auto' }}>
            {!token && !selected && <div className='row' style={{ width: '120px', margin: '1% 0 1%' }}>
                <GoogleLogin auto_select style={{ width: '100%' }} onSuccess={cred => parseJwt(cred)} onError={(err) => alert('Login Failed:', err)} />
            </div>}

            {token && <AdminConf user={{ email: token.email }} token={token.token} />}

        </div>
    )
};

export default Admin