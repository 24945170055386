import React from 'react';

export const Checkbox = ({ label, value, onChange, disabled, array, sublabel, checked }) => {
    function isChecked() {
        let res = (array) ? array.includes(value) : value
        if (checked) res = checked(value);
        return res;
    }
    return (
        <label style={{ margin: '0 1%' }}>
            <input className="form-check-input" type="checkbox" value={value} checked={onChange ? isChecked() : undefined} defaultChecked={onChange ? undefined : isChecked()} onChange={disabled ? null : onChange} disabled={disabled} readOnly={disabled}/>
            {label ? ' ' + label : null}{sublabel ? <p className='sublabel'>{'[' + sublabel + ']'}</p> : ''}
        </label>
    );
};

export const isNumeric = (num) => (typeof (num) === 'number' || typeof (num) === "string" && num.trim() !== '') && !isNaN(num);